/* istanbul ignore file */

export const BASE = 'base';
export const ICON = 'icon';
export const LG = 'lg';
export const MD = 'md';
export const SM = 'sm';
export const XL = 'xl';
export const XS = 'xs';
export const XXL = '2xl';
export const XXXL = '3xl';
export const XXXXL = '4xl';
export const XXXXXL = '5xl';
export const XXXXXXL = '6xl';
export const XXXXXXXL = '7xl';
export const XXXXXXXXL = '8xl';
