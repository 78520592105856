<template>
  <span class="align-top font-normal leading-none remixicon" :class="[disabledClasses, iconClass, sizeClass]" />
</template>

<script>
import { BASE, LG, SM, XS, XL, XXL, XXXL, XXXXL, XXXXXL, XXXXXXL, XXXXXXXL, XXXXXXXXL } from '../constants';

export const SIZE_MAP = {
  [XS]: 'text-xs',
  [SM]: 'text-sm',
  [BASE]: 'text-base',
  [LG]: 'text-lg',
  [XL]: 'text-xl',
  [XXL]: 'text-2xl',
  [XXXL]: 'text-3xl',
  [XXXXL]: 'text-4xl',
  [XXXXXL]: 'text-5xl',
  [XXXXXXL]: 'text-6xl',
  [XXXXXXXL]: 'text-7xl',
  [XXXXXXXXL]: 'text-8xl',
};
export const ALLOWED_SIZES = Object.keys(SIZE_MAP);

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    name: {
      type: String,
      required: true,
    },

    size: {
      type: String,
      default: BASE,
      validator: (value) => ALLOWED_SIZES.includes(value),
    },
  },

  computed: {
    disabledClasses() {
      return this.disabled ? 'cursor-not-allowed opacity-50' : null;
    },

    iconClass() {
      return `ri-${this.name}`;
    },

    sizeClass() {
      return SIZE_MAP[this.size];
    },
  },
};
</script>

<style scoped>
/* @import '~remixicon/fonts/remixicon.css'; */
</style>
